@font-face {
    font-family: "Roboto";
    src: url(fonts/Roboto-Regular.woff2) format("woff2"),
    url(fonts/Roboto-Regular.woff) format("woff"),
    url(fonts/Roboto-Regular.ttf) format("truetype");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: "Roboto";
    src: url(fonts/Roboto-Medium.woff2) format("woff2"),
    url(fonts/Roboto-Medium.woff) format("woff"),
    url(fonts/Roboto-Medium.ttf) format("truetype");
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family: "Roboto";
    src: url(fonts/Roboto-Bold.woff2) format("woff2"),
    url(fonts/Roboto-Bold.woff) format("woff"),
    url(fonts/Roboto-Bold.ttf) format("truetype");
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
}
